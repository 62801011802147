import React from 'react';
import "./analyticsUserAcquisition.scss"
import {Link} from "react-router-dom";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from '@mui/material';
import axios from "axios";
import dayjs from "dayjs";



interface Column {
    id: 'date' | 'installs' | 'sessions';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: Column[] = [
    { id: 'date', label: 'Дата', minWidth: 170 },
    { id: 'installs', label: 'Установки', minWidth: 100 },
    { id: 'sessions', label: 'Сессии', minWidth: 100 },
];


const AnalyticsUserAcquisition = () => {
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(1000);


    let minDate = '2023-12-13';
    let maxDate = dayjs().format("YYYY-MM-DD");

    let getUserAcquisitionUrl = `https://sort-station-mobile-dev.infomedia.digital/api/v1/DEVJzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLuseracquisition?min_date=${minDate}&max_date=${maxDate}&token=${sessionStorage.getItem("token")}`;

    const [post, setPost] = React.useState([]);

    React.useEffect(() => {
        axios.get(getUserAcquisitionUrl).then((response) => {
            setPost(response.data);
        });
    }, []);

    // console.log(post)
    if (!post) return null;

    let rows: any = post;

    rows = rows.sort((a:any,b:any) => dayjs(b.date).unix() - dayjs(a.date).unix())

    // const handleChangePage = (event: unknown, newPage: number) => {
    //     setPage(newPage);
    // };
    //
    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };

    return (
        <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ top: 0, minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row:any) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/*<TablePagination*/}
            {/*    rowsPerPageOptions={[1000, 1000, 1000]}*/}
            {/*    component="div"*/}
            {/*    count={rows.length}*/}
            {/*    rowsPerPage={rowsPerPage}*/}
            {/*    page={page}*/}
            {/*    onPageChange={handleChangePage}*/}
            {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
            {/*/>*/}
        </Paper>
    );
}

export default AnalyticsUserAcquisition