import React, {useState} from "react";
import Balance from "./pages/balance/Balance";
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
} from "react-router-dom";
import Users from "./pages/users/Users";
import Analytics from "./pages/analytics/Analytics";
import AnalyticsFunnel from "./pages/analytics/AnalyticsFunnel";
import AnalyticsUserAcquisition from "./pages/analytics/AnalyticsUserAcquisition";
import Navbar from "./components/navbar/Navbar";
import Menu from "./components/menu/Menu";
import Auth from "./pages/auth/Auth";
import "./styles/global.scss"
import BalanceNew from "./pages/balance_new/BalanceNew";


function App() {
    const [state, setState] = useState('');

    const Layout = () => {
        return(
            <div className="main">
                <Navbar/>
                <div className="container">
                    <div className="menuContainer">
                        <Menu/>
                    </div>
                    <div className="contentContainer">
                        <Outlet/>
                    </div>
                </div>
            </div>
        )
    }

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Layout/>,
            children:[
                {
                    path: "/",
                    element: <div>Панель Администратора</div>,
                },
                // {
                //     path: "/balance",
                //     element: <Balance/>,
                // },
                {
                    path: "/users",
                    element: <Users/>,
                },
                {
                    path: "/analytics",
                    element: <Analytics/>,
                },
                {
                    path: "/analytics/userAcquisition",
                    element: <AnalyticsUserAcquisition/>,
                },
                {
                    path: "/analytics/funnelDashboard",
                    element: <AnalyticsFunnel/>,
                },
                {
                    path: "/balanceNew",
                    element: <BalanceNew/>,
                },
            ]
        },
        {
            path: "/auth",
            element: <Auth setState={setState}/>
        }
    ]);

    
    if (sessionStorage.getItem("token") == null || sessionStorage.getItem("token") == 'null') {
        return <Auth setState={setState}/>
    }

    return (
    <RouterProvider router={router}/>
  );
}

export default App;
