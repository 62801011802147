import "./balanceNew.scss"
import DataTable from "../../components/dataTable/DataTable";
import {GridColDef} from "@mui/x-data-grid";
import React, {useState} from "react";
import Add from "../../components/add/Add";
import axios from "axios";
import CodeEditor from '@uiw/react-textarea-code-editor';
import BalanceKeyMultipleSelect from "../../components/balanceKeyMultipleSelect/BalanceKeyMultipleSelect";


const getDevBalanceConfigUrl = "https://sort-station-mobile-dev.infomedia.digital/api/v1/newBalanceDEVJzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLgetDevBalanceConfig?token=";
const saveDevBalanceChangesUrl = "https://sort-station-mobile-dev.infomedia.digital/api/v1/newBalanceDEVJzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLupdateDevBalance?token=";
const deployDevBalanceToProdUrl = "https://sort-station-mobile-dev.infomedia.digital/api/v1/newBalanceDEVJzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLdeployDevBalanceToProd?token=";

const addBalanceItemUrl = "";
const deleteBalanceItemUrl = "";
const saveBalanceChangesUrl = "";

const balanceKey1Values = [
    '',
    'common',
    'Orsk',
    'Volgograd',
    'Saratov',
    'Kazan',
    'Novorossiysk'
];


const balanceKey2Values = [
    '',
    'station',
    'city',
    'goals_config'
];

const balanceKey3ValuesStation = [
    '',
    'global',
    'sorting_slide_1',
    'sorting_slide_2',
    'sorting_slide_3',
    'offices',
    'managers',
    'depot',
];

const balanceKey3ValuesCity = [
    '',
    'global',
    'ApartmentBuilding_1',
    'ApartmentBuilding_2',
    'ApartmentBuilding_3',
    'TrainStation',
    'School',
    'Park',
    'CultureHouse',
    'Hospital',
    'University',
    'Landmark'
];

const balanceKey3ValuesGoals = [
    'goals'
];


const BalanceNew = () => {
    const [open, setOpen] = useState(false);
    const [post, setPost] = React.useState(null);

    const [code, setCode] = useState(
        ``
    );

    React.useEffect(() => {
        axios.get(getDevBalanceConfigUrl+sessionStorage.getItem("token")).then((response) => {
            setPost(response.data);
            setCode(response.data);
        });
    }, []);

    if (!post) return null;

    var codeJson = JSON.parse(code);

    // console.log("START codeJson[Orsk] = " + JSON.parse(codeJson)["Orsk"])
    // var key = "Orsk"
    // var selectedJsonPart = codeJson[key];
    // var selectedJsonPart = codeJson;

    const saveChanges = () => {
        // console.log(updatedItemsArray);
        axios.post(saveDevBalanceChangesUrl+sessionStorage.getItem("token"),  {"balance_config":JSON.stringify(codeJson)});
        console.log("codeJson = " + codeJson);
        console.log("codeJsonStringify = " + JSON.stringify(codeJson));
    };

    let balanceKey1 = '';
    let balanceKey2 = '';
    let balanceKey3 = '';

    if (sessionStorage.getItem("balanceKey1") != null)
    {
        var balanceKey1String = sessionStorage.getItem("balanceKey1");
        if(balanceKey1String != null)
            balanceKey1 = balanceKey1String
    }
    if (sessionStorage.getItem("balanceKey2") != null)
    {
        var balanceKey2String = sessionStorage.getItem("balanceKey2");
        if(balanceKey2String != null)
            balanceKey2 = balanceKey2String
    }
    if (sessionStorage.getItem("balanceKey3") != null)
    {
        var balanceKey3String = sessionStorage.getItem("balanceKey3");
        if(balanceKey3String != null)
            balanceKey3 = balanceKey3String
    }

    function ApplyKeys() {
        sessionStorage.setItem("balanceKey1", balanceKey1);
        sessionStorage.setItem("balanceKey2", balanceKey2);
        sessionStorage.setItem("balanceKey3", balanceKey3);

        // console.log("window.location.reload()")
        window.location.reload();
    }

    function SetBalanceKey1(balanceKey:string){
        balanceKey1 = balanceKey;
        balanceKey2 = '';
        balanceKey3 = '';
        ApplyKeys();
    }
    function SetBalanceKey2(balanceKey:string){
        balanceKey2 = balanceKey;
        balanceKey3 = '';
        ApplyKeys();
    }
    function SetBalanceKey3(balanceKey:string){
        balanceKey3 = balanceKey;
        ApplyKeys();
    }

    const deployBalanceToProd = () => {
        axios.get(deployDevBalanceToProdUrl+sessionStorage.getItem("token"));
    };

    const onJsonChange = (newValue: any) => {
        if(balanceKey1 != ''){
            if(balanceKey2 != ''){
                if(balanceKey3 != ''){
                    try{
                        // var jsonObj = JSON.parse(JSON.parse(codeJson[balanceKey1])[balanceKey2]);
                        // jsonObj[balanceKey3] = JSON.parse(newValue);
                        // JSON.parse(codeJson[balanceKey1])[balanceKey2] = JSON.stringify(jsonObj);
                        codeJson[balanceKey1][balanceKey2][balanceKey3] = JSON.parse(newValue)
                    }
                    catch (e){

                    }
                }
                else{
                    try {
                        // var jsonObj = JSON.parse(codeJson[balanceKey1]);
                        // jsonObj[balanceKey2] = JSON.parse(newValue);
                        // codeJson[balanceKey1] = JSON.stringify(jsonObj);
                        codeJson[balanceKey1][balanceKey2] = JSON.parse(newValue)
                    }
                    catch (e){
                    }
                }
            }
            else{
                try{
                    // var jsonObj = codeJson;
                    // console.log("s1")
                    // jsonObj[balanceKey1] = JSON.parse(newValue);
                    // console.log("s2")
                    // codeJson = JSON.stringify(jsonObj);
                    codeJson[balanceKey1] = JSON.parse(newValue)
                    console.log("Success")
                }
                catch (e){
                    console.log("Error")
                }
            }
        }
        else{
            try {
                codeJson = JSON.parse(newValue);
            }
            catch (e){

            }
        }
    };

    let selectedJsonPart = codeJson;

    if(balanceKey1 != ''){
        if(balanceKey2 != ''){
            if(balanceKey3 != ''){
                try {
                    selectedJsonPart = codeJson[balanceKey1][balanceKey2][balanceKey3];
                }catch (e){}
            }
            else{
                try{
                selectedJsonPart = codeJson[balanceKey1][balanceKey2]
                }catch (e){}
            }
        }
        else{
            try{
                // console.log("balanceKey1 = " + balanceKey1)
                // console.log("codeJson = " + codeJson)
                // console.log("codeJson[balanceKey1] = " + codeJson[balanceKey1])
                selectedJsonPart = codeJson[balanceKey1]
            }catch (e){
                console.log("Error")
            }
        }
    }
    else{
        try{
        selectedJsonPart = codeJson;
        }catch (e){}
    }

    var balanceKey3Values = [''];

    if (balanceKey2 == 'station')
        balanceKey3Values = balanceKey3ValuesStation;
    else if (balanceKey2 == 'city')
        balanceKey3Values = balanceKey3ValuesCity;
    else if (balanceKey2 == 'goals_config')
        balanceKey3Values = balanceKey3ValuesGoals;

    return(
        <div className="balance">
            <div className="info">
                <h1>Конфигурация Баланса</h1>
                <button onClick={() => saveChanges()}>Сохранить</button>
                <button onClick={() => deployBalanceToProd()}>Применить Баланс</button>
            </div>
            <div>
                <BalanceKeyMultipleSelect balanceKey={balanceKey1}
                                          setBalanceKey={SetBalanceKey1}
                                          balanceKeyValues={balanceKey1Values}
                />
                <BalanceKeyMultipleSelect balanceKey={balanceKey2}
                                          setBalanceKey={SetBalanceKey2}
                                          balanceKeyValues={balanceKey2Values}
                />
                <BalanceKeyMultipleSelect balanceKey={balanceKey3}
                                          setBalanceKey={SetBalanceKey3}
                                          balanceKeyValues={balanceKey3Values}
                />
            </div>

            <CodeEditor
                value={JSON.stringify(selectedJsonPart, null, " ")}
                language="json"
                placeholder="Please enter JSON."
                onChange={(evn) => onJsonChange(evn.target.value)}
                padding={15}
                style={{
                    backgroundColor: "#f5f5f5",
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
            />
        </div>
    )
}

export default BalanceNew