import "./users.scss"
import DataTable from "../../components/dataTable/DataTable";
import {GridColDef} from "@mui/x-data-grid";
import React, {useState} from "react";
import Add from "../../components/add/Add";
import axios from "axios";


const getUsersUrl = "https://sort-station-mobile-dev.infomedia.digital/api/v1/DEVJzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLusersinfoarray?token=";
const addUserUrl = "https://sort-station-mobile-dev.infomedia.digital/api/v1/DEVJzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLadduser?token=";
const deleteUserUrl = "https://sort-station-mobile-dev.infomedia.digital/api/v1/DEVJzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLdeleteuser?token=";
const saveUserChangesUrl = "https://sort-station-mobile-dev.infomedia.digital/api/v1/DEVJzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLupdateuserinfo?token=";


const Users = () => {
    const [open, setOpen] = useState(false);
    const [post, setPost] = React.useState({"columns":[], "rows": []});

    React.useEffect(() => {
        axios.get(getUsersUrl+sessionStorage.getItem("token")).then((response) => {
            setPost(response.data);
        });
    }, []);

    if (!post) return null;

    return(
        <div className="users">
            <div className="info">
                <h1>Пользователи</h1>
                <button onClick={() => setOpen(true)}>Добавить Пользователя</button>
            </div>
            <DataTable slug="users"
                       columns={post["columns"].filter((columns:any) => columns.field !== "password")}
                       rows={post["rows"]}
                       deleteElementUrl={deleteUserUrl}
                       saveChangesUrl={saveUserChangesUrl}/>
            {open && <Add slug="user"
                          columns={post["columns"]}
                          setOpen={setOpen}
                          addElementUrl={addUserUrl}/>}
        </div>
    )
}

export default Users