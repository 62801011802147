import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import "./balanceKeyMultipleSelect.scss";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// const names = [
//     'Orsk',
//     'Moscow',
//     'Volgograd',
// ];

type Props = {
    balanceKey: string;
    setBalanceKey:(balanceKey: string) => void;
    balanceKeyValues: string[];
    // setBalanceKey: Function;
    // onBalanceKeyChange: Function;
};

export default function BalanceKeyMultipleSelectCheckmarks(props: Props) {
    var balanceKey = props.balanceKey;

    const [personName, setPersonName] = React.useState<string>(balanceKey);

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            value,
        );

        // props.balanceKey = value;
        props.setBalanceKey(value);
        // props.onBalanceKeyChange();
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }} className="formControl" >
                {/*<InputLabel id="demo-multiple-checkbox-label" className="inputLabel" >Ключ</InputLabel>*/}
                Ключ
                <Select
                    className="select"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {props.balanceKeyValues.map((i) => (
                        <MenuItem key={i} value={i} className="menuItem">
                            <ListItemText primary={i} className="listItemText"/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}