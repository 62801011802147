import "./auth.scss"
import React, {useState} from "react";
import axios from "axios";
import PropTypes from 'prop-types';


const auth_url = "https://sort-station-mobile-dev.infomedia.digital/api/v1/DEVJzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLauth"
async function loginUser(credentials:any) {
    let response = await axios.post(auth_url, {"credentials": credentials})
    return response.data
}

export function logoutUser() {
    sessionStorage.clear();
    window.location.reload();
}


const Auth = ({ setState } :any) => {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');


    const handleSubmit = async (e: React.FormEvent<any>) => {
        e.preventDefault();

        e.defaultPrevented = true;
        const token = await loginUser({
            username,
            password
        });
        sessionStorage.setItem("token", token);

        if (token == null || token == "null") {
            setState('');
        }
        else{
            setState('1');
        }
    }

    return(
        <div className="login-wrapper">
            <h1>Авторизация</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    <p>Имя Пользователя</p>
                    <input type="text" onChange={e => setUserName(e.target.value)}/>
                </label>
                <label>
                    <p>Пароль</p>
                    <input type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <div>
                    <button type="submit">Войти</button>
                </div>
            </form>
        </div>
    )
}

Auth.propTypes = {
    setState: PropTypes.func.isRequired
};

export default Auth